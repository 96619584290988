import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Page } from '@templates'
import { formatCurrency, formatCurrencySemCifrao } from '@utils/masks'
import { StepRefin, TipoEmprestimo } from '@utils/enums'
import { salvarLeadRequest, trackingRefin } from '@store/refinanciamento/simulacao/actions'
import { Button } from '@interco/inter-ui/components/Button'
import { Separator } from '@atoms'
import { ApplicationState } from '@store/types'
import { Colors } from '@utils/Colors'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import IcChevronUp from '@interco/icons/core/action-navigation/ic_chevron_up'
import IcChevronDown from '@interco/icons/core/action-navigation/ic_chevron_down'
import Tag from '@utils/Tag'
import routes from '@routes/RefinV2/routes'
import { BridgeService } from '@services/bridge'

import { LineInfo } from './styles'
import Detalhes from './Detalhes'
import Parcelas from './Parcelas'

const ResultadoSimulacao = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showBottomsheetParcelas, setShowBottomsheetParcelas] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const { parcelaAtual, debtBalance, simulacaoSelecionada, dadosSimulacaoAlterados } = useSelector(
    (state: ApplicationState) => state.refinanciamento.simulacao,
  )
  const { contratosSelecionados } = useSelector(
    (state: ApplicationState) => state.refinanciamento.contratos,
  )
  const { sangueLaranja } = useSelector((state: ApplicationState) => state.session)

  useEffect(() => {
    BridgeService.tac({
      name: 'resultado_simulacao',
      actions: [
        'continuar_refinanciamento',
        'simular_condicao_oferta_seguro',
        'autorizar_termo_inss',
      ],
    })
  }, [])

  return (
    <Page
      stickyContainerFooterBottom="10px"
      positionFooter="sticky"
      titleHeader="Melhor condição"
      stickyFooter={
        <>
          <Separator margin="8px -24px" height="1px" />
          {showDetails ? (
            <>
              <div
                tabIndex={0}
                role="button"
                onKeyDown={() => {
                  setShowDetails(false)
                }}
                onClick={() => {
                  setShowDetails(false)
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignSelf: 'center',
                }}
              >
                <ColorWrapper fontWeight="700" color={Colors.PRIMARY500} margin="0 8px 0 0">
                  <TextParagraph variant="body-3">Exibir menos detalhes</TextParagraph>
                </ColorWrapper>
                <IcChevronDown width={24} height={24} color={Colors.PRIMARY500} />
              </div>
              <Detalhes />
            </>
          ) : (
            <div
              tabIndex={0}
              role="button"
              onKeyDown={() => {
                setShowDetails(true)
              }}
              onClick={() => {
                setShowDetails(true)
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'center',
              }}
            >
              <ColorWrapper fontWeight="700" color={Colors.PRIMARY500} margin="0 8px 0 0">
                <TextParagraph variant="body-3">Exibir mais detalhes</TextParagraph>
              </ColorWrapper>
              <IcChevronUp width={24} height={24} color={Colors.PRIMARY500} />
            </div>
          )}

          <Button
            fullWidth
            variant="primary"
            onClick={() => {
              BridgeService.tic({
                name: 'btn_solicitar_contratacao',
                action: 'solicitar_contratacao',
              })
              Tag.enviarTagueamentoApp({
                name: 'CONSIGNADO_REFIN_FLUXO',
                ref_figma: '2',
                tela: 'Resultado da simulação',
                ref_type: 'Botao',
                content_action: 'Toque',
                content_name: 'Solicitar contratação',
                product: 'CONSIGNADO_REFIN_INSS',
                scenario: sangueLaranja ? 'sangue_laranja' : '',
                section: 'resultado',
              })
              Tag.enviarTagueamentoWeb({
                element_name: 'Solicitar contratação',
                section_name: 'Resultado da simulação',
                element_action: 'click button',
                c_page: window.location.href,
                redirect_url: `${window.location.origin}${routes.CRIACAO_PROPOSTA}`,
                step: 'null',
              })
              dispatch(trackingRefin(StepRefin.RESULTADO_SIMULACAO))
              // 14/10: SIAPE e INSS passam para formalização
              if (dadosSimulacaoAlterados) {
                dispatch(
                  salvarLeadRequest({
                    callback: () => {
                      navigate(routes.CRIACAO_PROPOSTA)
                    },
                  }),
                )
              } else {
                navigate(routes.CRIACAO_PROPOSTA)
              }
            }}
          >
            Solicitar contratação
          </Button>
        </>
      }
    >
      <ColorWrapper fontWeight="600">
        <TextParagraph variant="headline-h3" margin="24px 0 8px">
          Troco liberado pra você
        </TextParagraph>
      </ColorWrapper>
      <ColorWrapper color={Colors.SUCCESS500} fontWeight="600">
        <TextParagraph variant="headline-h3">
          {formatCurrency(parcelaAtual.availableAmount)}
        </TextParagraph>
      </ColorWrapper>
      <TextParagraph variant="caption-1">Esse valor cai direto na sua conta.</TextParagraph>
      <Separator variant="small" margin="24px 0" />
      <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="0">
        <TextParagraph variant="headline-h3">Suas novas parcelas</TextParagraph>
      </ColorWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <ColorWrapper fontWeight="700" color={Colors.PRIMARY500} margin="0">
            <TextParagraph variant="body-3">
              {`${parcelaAtual?.numberOfInstallments}x ${
                formatCurrency(parcelaAtual?.installmentAmount) === ''
                  ? 'R$ 0,00'
                  : formatCurrency(parcelaAtual?.installmentAmount)
              } ${
                simulacaoSelecionada === TipoEmprestimo.CREDITO_PROTEGIDO
                  ? 'com proteção'
                  : 'sem proteção'
              }`}
            </TextParagraph>
          </ColorWrapper>
          <TextParagraph variant="caption-2" margin="0px">
            Total de taxas e encargos (CET): {formatCurrencySemCifrao(parcelaAtual?.cETAa)}% a.a.
          </TextParagraph>
        </div>
        <ColorWrapper
          fontWeight="700"
          color={Colors.PRIMARY500}
          onClick={() => setShowBottomsheetParcelas(true)}
        >
          <TextParagraph variant="body-3">Alterar</TextParagraph>
        </ColorWrapper>
      </div>

      <Separator margin="32px -24px" height="8px" color={Colors.GRAY100} />

      <ColorWrapper color={Colors.GRAY500} fontWeight="600">
        <TextParagraph variant="headline-h3">Detalhes do refinanciamento</TextParagraph>
      </ColorWrapper>

      <TextParagraph variant="caption-1" margin="0 0 24px 0">
        Essa simulação é válida por 24 horas.
      </TextParagraph>

      <LineInfo>
        <TextParagraph variant="caption-1">Saldo devedor pré-refinanciamento</TextParagraph>
        <TextParagraph variant="caption-1" colorWeight={500} bold>
          {formatCurrency(debtBalance)}
        </TextParagraph>
      </LineInfo>
      <LineInfo>
        <TextParagraph variant="caption-1">Troco liberado</TextParagraph>
        <TextParagraph variant="caption-1" colorWeight={500} bold>
          {formatCurrency(parcelaAtual.availableAmount)}
        </TextParagraph>
      </LineInfo>
      <LineInfo>
        <TextParagraph variant="caption-1">Novo valor disponível</TextParagraph>
        <TextParagraph variant="caption-1" colorWeight={500} bold>
          {formatCurrency(parcelaAtual.requestedAmount)}
        </TextParagraph>
      </LineInfo>

      <Separator margin="8px 0 24px 0" height="1px" />

      <ColorWrapper color={Colors.GRAY500} fontWeight="700" margin="0 0 24px 0">
        <TextParagraph variant="body-3">Contratos selecionados</TextParagraph>
      </ColorWrapper>
      <>
        {contratosSelecionados?.map((contrato, index) => (
          <div key={`${contrato.numeroProposta}`}>
            {index !== 0 ? <Separator variant="small" margin="16px 0" /> : <></>}
            <LineInfo>
              <TextParagraph variant="caption-1">Valor da parcela:</TextParagraph>
              <TextParagraph variant="caption-1" colorWeight={500} bold>
                {formatCurrency(contrato.valorProximaParcela)}
              </TextParagraph>
            </LineInfo>
            <LineInfo style={{ marginBottom: '0px' }}>
              <TextParagraph variant="caption-1">Nº da proposta:</TextParagraph>
              <TextParagraph variant="caption-1" colorWeight={500} bold>
                {contrato.numeroProposta}
              </TextParagraph>
            </LineInfo>
          </div>
        ))}
      </>

      {showBottomsheetParcelas ? (
        <Parcelas setShowBottomsheetParcelas={setShowBottomsheetParcelas} />
      ) : (
        <></>
      )}
    </Page>
  )
}

export default ResultadoSimulacao

import React, { useState } from 'react'

import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Page } from '@templates'
import { ApplicationState } from '@store/types'
import { Box, GridStyled, Loading, Paragraph, Separator } from '@atoms'
import { Contrato, ContratoSelecionado } from '@store/refinanciamento/contratos/types'
import * as actions from '@store/refinanciamento/contratos/actions'
import { ConveniosMultiContratosBloqueado, StepRefin } from '@utils/enums'
import { simulacaoRequest, trackingRefin } from '@store/refinanciamento/simulacao/actions'
import { CallbackSimulacaoType, InfoParcelas } from '@store/refinanciamento/simulacao/types'
import routes from '@routes/Refin/routes'
import InputHelperButton from '@molecules/InputHelperButton'
import { useTrackingRefin } from '@hooks'
import { Checkbox } from '@interco/inter-ui/components/Checkbox'
import { formatCurrency } from '@utils/masks'
import { consultaMargemInssRequest } from '@store/refinanciamento/consultaMargemInss/actions'
import Attention from '@interco/icons/core/status/ic_warning_circle'
import { Colors } from '@utils/Colors'
import { Button } from '@interco/inter-ui/components/Button'

import { dialogVariants, VariantDialogContratos } from './Bottomsheets/dialogVariants'
import { Grid, GridScroll } from './styles'
import { BottomSheetsContrato } from './Bottomsheets'

const Contratos = () => {
  const [showBottomSheet, setShowBottomSheet] = useState(false)
  const [variantDialog, setVariantDialog] = useState<VariantDialogContratos>(
    dialogVariants.SaibaMais,
  )

  const { listaContratos, matriculaSelecionada, contratosSelecionados } = useSelector(
    (state: ApplicationState) => state.refinanciamento.contratos,
  )

  const loading = useSelector(
    (state: ApplicationState) => state.refinanciamento.simulacao.loadingSimulacao,
  )
  const { loading: loadingInss } = useSelector(
    (state: ApplicationState) => state.refinanciamento.consultaMargemInss,
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const disableContinue = () => !contratosSelecionados?.length
  useTrackingRefin(StepRefin.CONTRATOS)

  const titleContracts = (numContratos: number) =>
    numContratos === 1
      ? `${numContratos} Contrato Disponível`
      : `${numContratos} Contratos Disponíveis`

  const counterContratos = () => {
    let qtdContratosTotal = 0
    listaContratos?.map((contratoConvenio) => {
      contratoConvenio?.listMatricula?.map((contratoMatricula) => {
        const onlyContratosDisponiveis = contratoMatricula?.listContrato?.filter(
          (contrato) => contrato.indisponivel !== true,
        )
        qtdContratosTotal += onlyContratosDisponiveis?.length
        return true
      })
      return true
    })
    return qtdContratosTotal
  }

  const hasContratoSelecionado = (contrato: string) =>
    contratosSelecionados?.some((item) => item.numeroContrato === contrato)

  const disableMultiContratos = (contrato: Contrato) =>
    Boolean(
      ConveniosMultiContratosBloqueado.indexOf(contrato.convenioDesc) >= 0 &&
        contratosSelecionados?.length &&
        contratosSelecionados[0].numeroContrato !== contrato.numeroContrato,
    )

  const disabledCheckbox = (matricula: string, contrato: Contrato) =>
    !(matricula === matriculaSelecionada || matriculaSelecionada === '') ||
    disableMultiContratos(contrato)

  const adicionaContrato = (contrato: ContratoSelecionado) => {
    dispatch(actions.setContratosSelecionados([...(contratosSelecionados || []), contrato]))
    dispatch(actions.setConvenioSelecionado(contrato.convenioDesc || ''))
    dispatch(actions.setConvenioCodigoSelecionado(contrato.convenioId))
    dispatch(actions.setOrgaoSelecionado(contrato.orgaoDesc || ''))
    dispatch(actions.setOrgaoCodigoSelecionado(contrato.orgaoId))
    dispatch(actions.setMatriculaSelecionada(contrato.matricula || ''))
    dispatch(actions.setCodProduto(contrato.codProduto))
  }

  const removeContrato = (contrato: string) => {
    const novaLista = contratosSelecionados?.filter((item) => item.numeroContrato !== contrato)
    if (novaLista?.length === 0) {
      dispatch(actions.setMatriculaSelecionada(''))
      dispatch(actions.setConvenioSelecionado(''))
      dispatch(actions.setConvenioCodigoSelecionado(0))
      dispatch(actions.setOrgaoSelecionado(''))
      dispatch(actions.setOrgaoCodigoSelecionado(0))
      dispatch(actions.setCodProduto(0))
    }
    dispatch(actions.setContratosSelecionados(novaLista || []))
  }

  const hasContratoInss = () => contratosSelecionados?.some((item) => item.convenioDesc === 'INSS')

  const navegaSelecaoSimulacao = (
    simulacoes: InfoParcelas[],
    simulacoesComSeguro: InfoParcelas[],
  ) => {
    if (simulacoes?.length > 0 && simulacoesComSeguro?.length > 0) {
      navigate(routes.SIMULACAO)
    } else if (
      (simulacoes?.length > 0 && simulacoesComSeguro?.length === 0) ||
      (simulacoes?.length === 0 && simulacoesComSeguro?.length > 0)
    ) {
      navigate(routes.CONFIRMAR_CONTATO)
    } else {
      navigate(routes.NAO_LIBERADO)
    }
  }

  return (
    <Page
      stickyFooter={
        <Button
          disabled={disableContinue() || loading || loadingInss}
          isLoading={loading || loadingInss}
          variant="primary"
          onClick={() => {
            if (hasContratoInss()) {
              setVariantDialog(dialogVariants.ConsultaMargemInss)
              setShowBottomSheet(true)
            } else {
              dispatch(
                simulacaoRequest({
                  erroCallback: () => {
                    navigate(routes.SEM_EMPRESTIMO)
                  },
                  callback: ({ simulations, simulationsWithInsurance }: CallbackSimulacaoType) => {
                    navegaSelecaoSimulacao(simulations, simulationsWithInsurance)
                    dispatch(trackingRefin(StepRefin.SIMULAR_CONTRATOS))
                  },
                }),
              )
            }
          }}
        >
          Simular refinanciamento
        </Button>
      }
    >
      <Paragraph
        variant="sora"
        fontWeight={600}
        fontSize="16px"
        lineHeight="19px"
        color={Colors.GRAY500}
      >
        {counterContratos() ? titleContracts(counterContratos()) : 'Contratos Disponíveis'}
      </Paragraph>
      <Paragraph fontSize="12px" lineHeight="15px" color={Colors.GRAY500}>
        Selecione um ou mais contratos para simular
      </Paragraph>
      <InputHelperButton
        fontWeight="700"
        margin="8px 0 0"
        fontSize="12px"
        lineHeight="15px"
        onClick={() => {
          setVariantDialog(dialogVariants.SaibaMais)
          setShowBottomSheet(true)
        }}
      />
      <Separator variant="small" margin="24px 0" />
      <Grid>
        {listaContratos?.length ? (
          listaContratos?.map((contratosAtivos) => (
            <GridScroll key={contratosAtivos.descricaoConvenio}>
              <Paragraph
                variant="sora"
                fontWeight={600}
                fontSize="16px"
                lineHeight="19px"
                color={Colors.GRAY500}
                margin="0 0 7px"
              >
                {contratosAtivos.descricaoConvenio}
              </Paragraph>
              {contratosAtivos.listMatricula.map((listaMatriculas) => (
                <Grid key={listaMatriculas.numeroMatricula}>
                  <Paragraph fontWeight="bold" color={Colors.GRAY500}>
                    {`${contratosAtivos.descricaoConvenio === 'INSS' ? 'Benefício' : 'Matrícula'} ${
                      listaMatriculas.numeroMatricula
                    }`}
                  </Paragraph>
                  {listaMatriculas.listContrato.map((contrato, contratoIndex) => {
                    if (contrato.indisponivel) {
                      return (
                        <Box
                          justify="space-between"
                          margin="16px 0px"
                          key={contrato.numeroContrato}
                        >
                          <Grid
                            onClick={() => {
                              if (disabledCheckbox(listaMatriculas.numeroMatricula, contrato)) {
                                setVariantDialog(dialogVariants.SelecaoContratos)
                                setShowBottomSheet(true)
                              } else {
                                setVariantDialog(dialogVariants.Indisponivel)
                                setShowBottomSheet(true)
                              }
                            }}
                          >
                            <Checkbox
                              disabled
                              id={`checkbox-contrato-${contrato.numeroContrato}-${{
                                contratoIndex,
                              }}`}
                              checked={false}
                              label={
                                <>
                                  <Paragraph
                                    fontSize="12px"
                                    lineHeight="15px"
                                    color={
                                      disabledCheckbox(listaMatriculas.numeroMatricula, contrato)
                                        ? '#'
                                        : Colors.GRAY400
                                    }
                                    margin="0px 0px 4px 0px"
                                  >
                                    Valor da parcela
                                  </Paragraph>
                                  <Paragraph
                                    fontWeight="bold"
                                    color={
                                      disabledCheckbox(listaMatriculas.numeroMatricula, contrato)
                                        ? '#'
                                        : Colors.GRAY500
                                    }
                                    margin="0px 0px 4px 0px"
                                  >
                                    {formatCurrency(contrato.valorProximaParcela)}
                                  </Paragraph>
                                  <Paragraph
                                    fontSize="12px"
                                    lineHeight="15px"
                                    color={
                                      disabledCheckbox(listaMatriculas.numeroMatricula, contrato)
                                        ? '#'
                                        : Colors.GRAY400
                                    }
                                    margin="0px"
                                  >
                                    {`Nº da proposta:  ${contrato.numeroProposta}`}
                                  </Paragraph>
                                </>
                              }
                            />
                          </Grid>

                          <Attention height={16} width={16} color={Colors.ALERT500} />
                        </Box>
                      )
                    }
                    return (
                      <Box
                        justify="flex-start"
                        margin="16px 0px"
                        key={contrato.numeroContrato}
                        actived={hasContratoSelecionado(contrato.numeroContrato)}
                      >
                        <Grid
                          onClick={() => {
                            if (disabledCheckbox(listaMatriculas.numeroMatricula, contrato)) {
                              setVariantDialog(dialogVariants.SelecaoContratos)
                              setShowBottomSheet(true)
                            }
                          }}
                        >
                          <Checkbox
                            disabled={disabledCheckbox(listaMatriculas.numeroMatricula, contrato)}
                            id={`checkbox-contrato-${contrato.numeroContrato}-${{
                              contratoIndex,
                            }}`}
                            checked={hasContratoSelecionado(contrato.numeroContrato)}
                            onChange={() => {
                              if (
                                contratosSelecionados?.length &&
                                hasContratoSelecionado(contrato.numeroContrato)
                              ) {
                                removeContrato(contrato.numeroContrato)
                              } else {
                                adicionaContrato({
                                  ...contrato,
                                  matricula: listaMatriculas.numeroMatricula,
                                })
                              }
                            }}
                            label={
                              <>
                                <Paragraph
                                  fontSize="12px"
                                  lineHeight="15px"
                                  color={
                                    disabledCheckbox(listaMatriculas.numeroMatricula, contrato)
                                      ? '#'
                                      : Colors.GRAY400
                                  }
                                  margin="0px 0px 4px 0px"
                                >
                                  Valor da parcela
                                </Paragraph>
                                <Paragraph
                                  fontWeight="bold"
                                  color={
                                    disabledCheckbox(listaMatriculas.numeroMatricula, contrato)
                                      ? '#'
                                      : Colors.GRAY500
                                  }
                                  margin="0px 0px 4px 0px"
                                >
                                  {formatCurrency(contrato.valorProximaParcela)}
                                </Paragraph>
                                <Paragraph
                                  fontSize="12px"
                                  lineHeight="15px"
                                  color={
                                    disabledCheckbox(listaMatriculas.numeroMatricula, contrato)
                                      ? '#'
                                      : Colors.GRAY400
                                  }
                                  margin="0px"
                                >
                                  {`Nº da proposta:  ${contrato.numeroProposta}`}
                                </Paragraph>
                              </>
                            }
                          />
                        </Grid>
                      </Box>
                    )
                  })}
                </Grid>
              ))}
            </GridScroll>
          ))
        ) : (
          <GridStyled>
            <Loading />
          </GridStyled>
        )}
      </Grid>
      {showBottomSheet ? (
        <BottomSheetsContrato
          setShowBottomSheet={setShowBottomSheet}
          variant={variantDialog}
          customTextButton={
            variantDialog === dialogVariants.ConsultaMargemInss ? 'Continuar' : 'Entendi'
          }
          linkAutorizacao={variantDialog === dialogVariants.ConsultaMargemInss}
          customActionButton={() => {
            if (variantDialog === dialogVariants.ConsultaMargemInss) {
              dispatch(
                consultaMargemInssRequest({
                  callback: () => {
                    dispatch(
                      simulacaoRequest({
                        erroCallback: () => {
                          navigate(routes.SEM_EMPRESTIMO)
                        },
                        callback: ({
                          simulations,
                          simulationsWithInsurance,
                        }: CallbackSimulacaoType) => {
                          navegaSelecaoSimulacao(simulations, simulationsWithInsurance)
                          dispatch(trackingRefin(StepRefin.SIMULAR_CONTRATOS))
                        },
                      }),
                    )
                  },
                }),
              )
            }
            setShowBottomSheet(false)
          }}
        />
      ) : (
        <></>
      )}
    </Page>
  )
}

export default Contratos

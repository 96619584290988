import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'

import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import ListCheck from '@interco/icons/core/action-navigation/ic_checklist'
import CheckBack from '@interco/icons/core/finance/ic_money_check_back'
import FrameDocument from '@interco/icons/core/action-navigation/ic_expand'
import Attachment from '@interco/icons/core/action-navigation/ic_attachment'
import CameraOn from '@interco/icons/core/media-communication/ic_photo'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import {
  sendToS3,
  setAnexos,
  setIsError,
  setIsLoading,
} from '@store/refinanciamento/fotoDocumentos/actions'
import { ApplicationState } from '@store/types'
import { BridgeService } from '@services/bridge'
import { Button } from '@interco/inter-ui/components/Button'
import { ConveniosCodes, StepRefin } from '@utils/enums'
import { Page } from '@templates'
import routes from '@routes/RefinV2/routes'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { Colors } from '@utils/Colors'
import { trackingRefin } from '@store/refinanciamento/simulacao/actions'
import { Loading } from '@atoms'
import Tag from '@utils/Tag'
import { postBiometriaDadoSave } from '@store/biometria/actions'
import { fileToBase64 } from '@utils/functions'

import { Container, CustomButton, DivLine, InputFile, LineDescription } from '../styles'

const MaxFileSize = 2000
const ModoEnvio = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [uploadedCount, setUploadedCount] = useState(0)
  const [uploadedQuantity, setUploadedQuantity] = useState(0)
  const [bottomSheetQuantity, setBottomSheetQuantity] = useState(false)
  const [openLoading, setOpenLoading] = useState(false)
  const [bottomSheetSize, setBottomSheetSize] = useState(false)
  const { anexos, isLoading, isDone } = useSelector(
    (state: ApplicationState) => state.refinanciamento.fotoDocumentos,
  )
  const { convenioCodigo } = useSelector(
    (state: ApplicationState) => state.refinanciamento.contratos,
  )
  const { origem, isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { accessToken } = useSelector((state: ApplicationState) => state.session)
  const anexosSizes = anexos?.map((anexo) => anexo?.anexo?.size)
  const somaSizes =
    Number(anexosSizes.reduce((total, numero) => Number(total) + Number(numero), 0)) / 1000

  useEffect(() => {
    BridgeService.tac({
      name: 'modo-envio',
      actions: ['solicitar_documento_id'],
    })
  }, [])

  useEffect(() => {
    if (somaSizes > MaxFileSize) setBottomSheetSize(true)
  }, [somaSizes])

  const anexarDocumentos = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files) {
      const files = Array.from(e.target.files)
      setUploadedQuantity(files.length)
      const size = files && files.reduce((sum, file) => sum + file.size, 0) / 1000
      if (files?.length > 2) {
        setBottomSheetQuantity(true)
      } else if ((size && size > MaxFileSize) || somaSizes + Number(size) > MaxFileSize) {
        setBottomSheetSize(true)
      } else {
        files?.forEach((file) => {
          const reader = new window.FileReader()
          reader.onloadend = async () => {
            if (file?.type === 'application/pdf') {
              dispatch(
                setAnexos({
                  anexo: {
                    id: uuidv4(),
                    nome: file.name,
                    tipoExtensao: file.type,
                    size: file.size,
                    blobs: [
                      {
                        height: 200,
                        width: 200,
                        data: await fileToBase64(file),
                      },
                    ],
                  },
                  tipo: 'RG_CNH',
                }),
              )
              setUploadedCount((prevCount) => prevCount + 1)
            } else {
              const image = new Image()
              image.src = reader.result as string
              image.onload = () => {
                dispatch(
                  setAnexos({
                    anexo: {
                      id: uuidv4(),
                      nome: file?.name || '',
                      tipoExtensao: file?.type || '',
                      size: file?.size,
                      blobs: [
                        {
                          height: image.height,
                          width: image.width,
                          data: image.src,
                        },
                      ],
                    },
                    tipo: 'RG_CNH',
                  }),
                )
                setUploadedCount((prevCount) => prevCount + 1)
              }
            }
          }

          if (file?.type === 'application/pdf') {
            reader.readAsArrayBuffer(file)
          } else {
            reader.readAsDataURL(file as Blob)
          }
        })
      }
    }
  }

  const sendPhoto = useCallback(() => {
    dispatch(setIsLoading(true))
    dispatch(setIsError(false))
    dispatch(
      sendToS3({
        tipo: 'RG_CNH',
        anexos,
        callback: () => {
          navigate(routes.STATUS_DOCUMENTO)
        },
      }),
    )
  }, [anexos, dispatch, navigate])

  useEffect(() => {
    if (anexos.length > 0 && uploadedQuantity === uploadedCount) {
      sendPhoto()
    }
  }, [uploadedCount, sendPhoto, anexos.length, anexos, uploadedQuantity])

  useEffect(() => {
    if (isDone) {
      dispatch(trackingRefin(StepRefin.ANEXO_DE_DOCUMENTOS_CONCLUIDO))
      Tag.enviarTagueamentoWeb({
        element_name: '',
        section_name: 'Anexo de documento',
        element_action: 'submit',
        c_page: window.location.href,
        redirect_url: `${process.env.REACT_APP_BIOMETRIA_MODULO}`,
        step: 'null',
      })
      if (convenioCodigo === ConveniosCodes.SIAPE_SERVIDOR_FEDERAL) {
        navigate(routes.STATUS_DOCUMENTO)
      } else {
        BridgeService.tic({
          name: 'btn_solicitar_biometria',
          action: 'solicitar_biometria',
        })
        dispatch(
          postBiometriaDadoSave({
            callback: (biometricToken: string) => {
              dispatch(trackingRefin(StepRefin.ORIENTACOES_VIDEO_SELFIE))
              window.location.href = BridgeService.isBrowser()
                ? `${process.env.REACT_APP_BIOMETRIA_MODULO}?biometricToken=${biometricToken}&accessToken=${accessToken}&origem=${origem}&idConvenio=${convenioCodigo}&produto=REFINANCIAMENTO`
                : `${process.env.REACT_APP_BIOMETRIA_MODULO}?biometricToken=${biometricToken}&origem=${origem}&idConvenio=${convenioCodigo}&produto=REFINANCIAMENTO`
            },
          }),
        )
      }
    }
  }, [accessToken, convenioCodigo, dispatch, isDone, navigate, origem, isApp])

  return (
    <Page
      footerTwoButtonsSpace="180px"
      positionFooter="sticky"
      stickyContainerFooterBottom="16px"
      titleHeader="Foto do documento"
      stickyFooter={
        <>
          <CustomButton
            onClick={() => {
              dispatch(trackingRefin(StepRefin.FOTOGRAFAR_DOCUMENTO))
              BridgeService.tic({
                name: 'btn_tirar_foto',
                action: 'tirar_foto',
              })
              navigate(routes.FOTO_DOCUMENTO)
            }}
          >
            <ColorWrapper color={Colors.GRAY500} fontWeight="700">
              <TextParagraph variant="body-3">Tirar uma foto agora</TextParagraph>
            </ColorWrapper>
            <CameraOn width={24} height={24} color={Colors.PRIMARY500} />
          </CustomButton>
          <CustomButton
            onClick={() => {
              document.getElementById('input-camera')?.click()
            }}
          >
            <ColorWrapper color={Colors.GRAY500} fontWeight="700">
              <TextParagraph variant="body-3">Prefiro anexar a foto</TextParagraph>
            </ColorWrapper>
            <Attachment width={24} height={24} color={Colors.PRIMARY500} />
            <InputFile
              id="input-camera"
              type="file"
              multiple
              accept="image/*, application/pdf"
              onChange={async (e) => {
                dispatch(trackingRefin(StepRefin.ANEXAR_DOCUMENTO))
                anexarDocumentos(e)
              }}
            />
          </CustomButton>
        </>
      }
    >
      <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="0 0 16px 0">
        <TextParagraph variant="headline-h1">
          Para enviar o documento, certifique-se que:
        </TextParagraph>
      </ColorWrapper>
      <Container>
        <LineDescription>
          <ListCheck width={24} height={24} color={Colors.GRAY500} />
          <DivLine>
            <TextParagraph variant="body-3">
              É possível ler todas as informações do documento na foto.
            </TextParagraph>
          </DivLine>
        </LineDescription>
        <LineDescription>
          <CheckBack width={24} height={24} color={Colors.GRAY500} />
          <DivLine>
            <TextParagraph variant="body-3">
              Não há reflexos na foto. Se precisar, retire o plástico protetor.
            </TextParagraph>
          </DivLine>
        </LineDescription>
        <LineDescription>
          <FrameDocument width={24} height={24} color={Colors.GRAY500} />
          <DivLine>
            <TextParagraph variant="body-3">
              O documento está centralizado e que nada está na frente dele.
            </TextParagraph>
          </DivLine>
        </LineDescription>
      </Container>
      {isLoading || openLoading ? (
        <BottomSheet
          onClose={() => {
            setOpenLoading(false)
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Loading width={30} height={30} />
          </div>

          <ColorWrapper
            color={Colors.GRAY500}
            fontWeight="700"
            margin="24px 0"
            style={{ textAlign: 'center' }}
          >
            <TextParagraph variant="body-1">Carregando arquivo...</TextParagraph>
          </ColorWrapper>
        </BottomSheet>
      ) : (
        <></>
      )}
      {bottomSheetSize ? (
        <BottomSheet onClose={() => setBottomSheetSize(false)}>
          <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="0px 0 16px 0">
            <TextParagraph variant="headline-h3">Tamanho máximo excedido</TextParagraph>
          </ColorWrapper>
          <TextParagraph variant="body-3">
            Os arquivos enviados devem somar no máximo 2MB de tamanho. Verifique o tamanho e tente
            novamente.
          </TextParagraph>
          <br />
          <br />
          <Button
            fullWidth
            onClick={() => {
              setBottomSheetSize(false)
            }}
          >
            Fechar
          </Button>
        </BottomSheet>
      ) : (
        <></>
      )}
      {bottomSheetQuantity ? (
        <BottomSheet onClose={() => setBottomSheetQuantity(false)}>
          <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="0px 0 16px 0">
            <TextParagraph variant="headline-h3">Quantidade máxima excedida</TextParagraph>
          </ColorWrapper>
          <TextParagraph variant="body-3">
            Envie no máximo 2 arquivos contendo frente e verso. Caso frente e verso não estejam
            presentes em apenas um arquivo.
          </TextParagraph>
          <br />
          <br />
          <Button
            fullWidth
            onClick={() => {
              setBottomSheetQuantity(false)
            }}
          >
            Fechar
          </Button>
        </BottomSheet>
      ) : (
        <></>
      )}
    </Page>
  )
}
export default ModoEnvio

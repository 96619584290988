import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Alert } from '@interco/inter-ui/components/Alert'
import { Page } from '@templates'
import ChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import { ApplicationState } from '@store/types'
import Attention from '@interco/icons/core/status/ic_warning_circle'
import Refresh from '@interco/icons/core/action-navigation/ic_rotate_right'
import SolidClose from '@interco/icons/core/status/ic_close_circle_fill'
import CheckFill from '@interco/icons/core/status/ic_check_circle_fill'
import { BridgeService } from '@services/bridge'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { Colors } from '@utils/Colors'
import { Box } from '@atoms'
import { ConveniosCodes, StepRefin } from '@utils/enums'
import { Button } from '@interco/inter-ui/components/Button'
import { postBiometriaDadoSave } from '@store/biometria/actions'
import routes from '@routes/RefinV2/routes'
import { trackingRefin } from '@store/refinanciamento/simulacao/actions'

import { WrapText } from '../styles'
import BottomsheetTipoDoc from './BottomsheetTipoDoc'

const StatusDocumentos = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [openTipoDoc, setOpenTipoDoc] = useState(false)
  const { isError, isDone } = useSelector(
    (state: ApplicationState) => state.refinanciamento.fotoDocumentos,
  )
  const { isError: isErrorContraCheque, isDone: isDoneContraCheque } = useSelector(
    (state: ApplicationState) => state.refinanciamento.contrachequeDocumentos,
  )
  const { convenioCodigo } = useSelector(
    (state: ApplicationState) => state.refinanciamento.contratos,
  )
  const { origem } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { accessToken } = useSelector((state: ApplicationState) => state.session)

  useEffect(() => {
    BridgeService.tac({
      name: 'envio_documentos',
      actions: ['buscar_documentos'],
    })
  }, [])

  return (
    <Page
      titleHeader="Contratação"
      stickyFooter={
        <>
          {isError ? (
            <Alert customBackgroundColor={Colors.ERROR500} customIcon={<Attention color="white" />}>
              <ColorWrapper color={Colors.WHITE} fontWeight="700">
                <TextParagraph variant="body-3">Erro ao enviar</TextParagraph>
              </ColorWrapper>
            </Alert>
          ) : (
            <></>
          )}
          {ConveniosCodes.SIAPE_SERVIDOR_FEDERAL === convenioCodigo ||
          (ConveniosCodes.SIAPE_SERVIDOR_FEDERAL !== convenioCodigo && isDone) ? (
            <Button
              disabled={
                !isDone ||
                (ConveniosCodes.SIAPE_SERVIDOR_FEDERAL === convenioCodigo && !isDoneContraCheque)
              }
              onClick={() => {
                BridgeService.tic({
                  name: 'btn_solicitar_biometria',
                  action: 'solicitar_biometria',
                })
                dispatch(
                  postBiometriaDadoSave({
                    callback: (biometricToken: string) => {
                      dispatch(trackingRefin(StepRefin.ORIENTACOES_VIDEO_SELFIE))
                      window.location.href = BridgeService.isBrowser()
                        ? `${process.env.REACT_APP_BIOMETRIA_MODULO}?biometricToken=${biometricToken}&accessToken=${accessToken}&origem=${origem}&idConvenio=${convenioCodigo}&produto=REFINANCIAMENTO`
                        : `${process.env.REACT_APP_BIOMETRIA_MODULO}?biometricToken=${biometricToken}&origem=${origem}&idConvenio=${convenioCodigo}&produto=REFINANCIAMENTO`
                    },
                  }),
                )
              }}
            >
              Continuar
            </Button>
          ) : (
            <></>
          )}
        </>
      }
    >
      <ColorWrapper color={Colors.GRAY500} fontWeight="600" margin="0 0 8px 0">
        <TextParagraph variant="headline-h2">
          {ConveniosCodes.SIAPE_SERVIDOR_FEDERAL === convenioCodigo
            ? 'Envie os documentos abaixo'
            : 'Envie o seu documento de identificação'}
        </TextParagraph>
      </ColorWrapper>
      <TextParagraph variant="body-3">
        Você pode tirar uma foto ou enviar uma que já possui em seu celular.
      </TextParagraph>

      <Box
        key="foto"
        onClick={() => {
          setOpenTipoDoc(true)
        }}
        actived={false}
        style={{
          marginTop: '40px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {isError ? (
          <>
            <SolidClose height={24} width={24} color={Colors.ERROR500} />
            <WrapText style={{ width: '75%' }}>
              <ColorWrapper color={Colors.GRAY500} fontWeight="700">
                <TextParagraph variant="body-3">Documento de identificação</TextParagraph>
              </ColorWrapper>
              <ColorWrapper color={Colors.PRIMARY500} fontWeight="700">
                <TextParagraph variant="caption-1">Conferir dicas pra foto</TextParagraph>
              </ColorWrapper>
            </WrapText>
            <Refresh height={24} width={24} color={Colors.PRIMARY500} />
          </>
        ) : (
          <>
            {isDone ? <CheckFill height={24} width={24} color={Colors.SUCCESS500} /> : <></>}
            <WrapText style={{ width: '75%' }}>
              <ColorWrapper color={Colors.GRAY500} fontWeight="700">
                <TextParagraph variant="body-3">Documento de identificação</TextParagraph>
              </ColorWrapper>
              <TextParagraph variant="caption-1">CNH, RG ou Carteira funcional</TextParagraph>
            </WrapText>
            <ChevronRight height={24} width={24} color={Colors.PRIMARY500} />
          </>
        )}
      </Box>

      {ConveniosCodes.SIAPE_SERVIDOR_FEDERAL === convenioCodigo ? (
        <Box
          key="contracheque"
          onClick={() => {
            navigate(routes.MODO_ENVIO_CONTRA_CHEQUE)
          }}
          actived={false}
          style={{
            marginTop: '40px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {isErrorContraCheque ? (
            <>
              <SolidClose height={24} width={24} color={Colors.ERROR500} />
              <WrapText style={{ width: '75%' }}>
                <ColorWrapper color={Colors.GRAY500} fontWeight="700">
                  <TextParagraph variant="body-3">Contra Cheque</TextParagraph>
                </ColorWrapper>
                <ColorWrapper color={Colors.PRIMARY500} fontWeight="700">
                  <TextParagraph variant="caption-1">Conferir dicas pra foto</TextParagraph>
                </ColorWrapper>
              </WrapText>
              <Refresh height={24} width={24} color={Colors.PRIMARY500} />
            </>
          ) : (
            <>
              {isDoneContraCheque ? (
                <CheckFill height={24} width={24} color={Colors.SUCCESS500} />
              ) : (
                <></>
              )}
              <WrapText>
                <ColorWrapper color={Colors.GRAY500} fontWeight="700">
                  <TextParagraph variant="body-3">Contra Cheque</TextParagraph>
                </ColorWrapper>
              </WrapText>
              <ChevronRight height={24} width={24} />
            </>
          )}
        </Box>
      ) : (
        <></>
      )}
      {openTipoDoc ? <BottomsheetTipoDoc setOpenTipoDoc={setOpenTipoDoc} /> : <></>}
    </Page>
  )
}

export default StatusDocumentos

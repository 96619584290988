import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'

import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { clearError } from '@store/ui/error/actions'
import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'
import { validaEnvHomeByBridge } from '@utils/functions'
import { ApplicationState } from '@store/types'
import { BridgeService } from '@services/bridge'
import Tag from '@utils/Tag'
import { ConveniosCodes } from '@utils/enums'

import { connector, Props } from './types'
import { Header } from './styles'

const ErrorMessage = ({
  showError = false,
  actionCallback,
  block,
  dispatch,
  functionCallback,
}: Props) => {
  const [open, setOpen] = useState(showError)
  const { origemGoBack, isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { convenioCodigo, convenioSelecionado } = useSelector(
    (state: ApplicationState) => state.refinanciamento.contratos,
  )

  useEffect(() => {
    dispatch(clearError())
  }, [dispatch])

  useEffect(() => {
    if (open) {
      Tag.enviarTagueamentoApp({
        ref_figma: '7',
        name: 'CONSIGNADO_REFIN_FLUXO',
        tela: `Erro dialog`,
        ref_type: 'Feedback',
        content_action: 'Dado carregado',
        content_name: `Estamos resolvendo um erro`,
        product:
          ConveniosCodes.SIAPE_SERVIDOR_FEDERAL === convenioCodigo
            ? 'REFINANCIAMENTO_CONSIGNADO_SIAPE'
            : `REFINANCIAMENTO_CONSIGNADO_${convenioSelecionado}`,
      })
      BridgeService.tac({
        name: 'error-dialog',
        actions: [
          'buscar_contratos',
          'continuar_refinanciamento',
          'autorizar_termo_inss',
          'simular_condicao_oferta_seguro',
          'solicitar_contratacao',
          'criar_proposta',
          'buscar_documentos',
          'enviar_foto_documento',
          'solicitar_biometria',
          'confirmar_contratacao',
        ],
        isError: true,
      })
    }
  }, [convenioCodigo, convenioSelecionado, open])

  useEffect(() => {
    setOpen(showError)
  }, [showError, setOpen])

  if (open) {
    return (
      <BottomSheet
        onClose={
          !block
            ? () => {
                setOpen(false)
                setTimeout(() => {
                  dispatch(clearError())
                }, 600)
              }
            : () => null
        }
      >
        <Header>
          <Text variant="headline-h3" color="typography" semiBold>
            Estamos resolvendo um erro
          </Text>
        </Header>
        <Text variant="body-3">
          Mas fique tranquilo, em breve você poderá continuar o seu processo.
        </Text>

        <Button
          fullWidth
          style={{ marginTop: '16px' }}
          onClick={() => {
            Tag.enviarTagueamentoApp({
              ref_figma: '8',
              name: 'CONSIGNADO_REFIN_FLUXO',
              tela: `Erro dialog`,
              ref_type: 'Botao',
              content_action: 'Toque',
              content_name: `Tentar novamente`,
              product:
                ConveniosCodes.SIAPE_SERVIDOR_FEDERAL === convenioCodigo
                  ? 'REFINANCIAMENTO_CONSIGNADO_SIAPE'
                  : `REFINANCIAMENTO_CONSIGNADO_${convenioSelecionado}`,
            })
            setOpen(false)
            setTimeout(() => {
              dispatch(clearError())
              if (actionCallback instanceof Function) {
                dispatch(actionCallback())
              } else if (functionCallback instanceof Function) {
                functionCallback()
              }
            }, 600)
          }}
        >
          Tentar novamente
        </Button>
        <Button
          fullWidth
          variant="secondary"
          style={{ marginTop: '16px' }}
          onClick={() => {
            Tag.enviarTagueamentoApp({
              ref_figma: '8',
              name: 'CONSIGNADO_REFIN_FLUXO',
              tela: `Erro dialog`,
              ref_type: 'Botao',
              content_action: 'Toque',
              content_name: `Voltar para home`,
              product:
                ConveniosCodes.SIAPE_SERVIDOR_FEDERAL === convenioCodigo
                  ? 'REFINANCIAMENTO_CONSIGNADO_SIAPE'
                  : `REFINANCIAMENTO_CONSIGNADO_${convenioSelecionado}`,
            })
            setOpen(false)
            window.location.href = validaEnvHomeByBridge(String(origemGoBack), isApp) || ''
          }}
        >
          Voltar para home
        </Button>
      </BottomSheet>
    )
  }
  return <></>
}

export default connector(ErrorMessage)

import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { ApplicationState } from '@store/types'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import GridStyled from '@atoms/GridStyled'
import { Colors } from '@utils/Colors'
import { Button } from '@interco/inter-ui/components/Button'
import Tag from '@utils/Tag'
import { Page } from '@templates'
import { validaEnvHomeByBridge } from '@utils/functions'
import IcHandMoney from '@interco/icons/core/human/ic_hand_money'

const OfertaPortabilidade = () => {
  const { origemGoBack, isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { utmCampaign, sangueLaranja } = useSelector((state: ApplicationState) => state.session)

  useEffect(() => {
    Tag.enviarTagueamentoApp({
      name: 'CONSIGNADO_REFIN_ACESSO',
      ref_figma: '4',
      tela: 'Refinanciamento Consig',
      content_action: 'Dado carregado',
      utm_campaign: utmCampaign,
      product: 'REFINANCIAMENTO_CONSIGNADO',
      scenario: sangueLaranja ? 'sangue_laranja' : '',
      section: 'Desqualificacao',
    })
  }, [sangueLaranja, utmCampaign])

  const redirectGoBack = () =>
    sangueLaranja
      ? `${process.env.REACT_APP_SIMULADOR_CONSIGNADO}/novo?origem=app&familia=publico&origemgoback=emprestimo&sangueLaranja=true`
      : validaEnvHomeByBridge(String(origemGoBack), isApp) || ''

  return (
    <Page
      showLeftIcon={false}
      stickyFooter={
        <Button
          variant="link"
          onClick={() => {
            window.location.href = redirectGoBack()
          }}
        >
          Voltar para home
        </Button>
      }
    >
      <GridStyled justify="space-around" margin="150px 0 24px 0">
        <div style={{ padding: '16px', borderRadius: '60px', background: Colors.GRAY100 }}>
          <IcHandMoney width={32} height={32} color={Colors.GRAY500} />
        </div>
      </GridStyled>
      <ColorWrapper fontWeight="600" style={{ marginBottom: '8px', textAlign: 'center' }}>
        <TextParagraph variant="headline-h1" textAlign="center">
          Ainda não conseguimos te oferecer esse crédito
        </TextParagraph>
      </ColorWrapper>

      <TextParagraph variant="body-3" textAlign="center">
        Mas, entraremos em contato pra te oferecer outras condições.
      </TextParagraph>
    </Page>
  )
}
export default OfertaPortabilidade

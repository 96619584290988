import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { StepRefin } from '@utils/enums'
import { useTrackingRefin } from '@hooks'
import { ApplicationState } from '@store/types'
import { Page } from '@templates'
import { Button } from '@interco/inter-ui/components/Button'
import { validaEnvHomeByBridge } from '@utils/functions'
import { GridStyled } from '@atoms'
import { ErrorIcon } from '@icons/Signals'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import Tag from '@utils/Tag'
import { BridgeService } from '@services/bridge'

const RefinanciamentoNaoLiberado = () => {
  const { origemGoBack, isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  useTrackingRefin(StepRefin.NAO_LIBERADO)

  useEffect(() => {
    BridgeService.tac({
      name: 'nao-liberado',
      actions: [
        'continuar_refinanciamento',
        'criar_proposta',
        'buscar_documentos',
        'autorizar_termo_inss',
        'simular_condicao_oferta_seguro',
      ],
      isError: true,
    })
  }, [])

  useEffect(() => {
    Tag.enviarTagueamentoWeb({
      element_name: '',
      section_name: 'Não liberado',
      element_action: 'page_loaded',
      c_page: window.location.href,
      redirect_url: '',
      step: 'null',
    })
  }, [])

  return (
    <Page
      footerTwoButtonsSpace="200px"
      showLeftIcon={false}
      stickyFooter={
        <Button
          variant="primary"
          onClick={() => {
            window.location.href = validaEnvHomeByBridge(String(origemGoBack), isApp) || ''
          }}
        >
          Voltar ao início
        </Button>
      }
    >
      <GridStyled>
        <ErrorIcon />
      </GridStyled>

      <ColorWrapper fontWeight="600" margin="24px 0 8px 0">
        <TextParagraph variant="headline-h1" textAlign="center">
          Não é possível entregar o crédito solicitado
        </TextParagraph>
      </ColorWrapper>
      <ColorWrapper margin="16px 0 16px 0">
        <TextParagraph variant="body-3">
          Isso pode acontecer pelo uso total da margem ou por nossas políticas de crédito.
        </TextParagraph>
      </ColorWrapper>
      <ColorWrapper>
        <TextParagraph variant="body-3">
          Você pode conferir com o órgão responsável por seu salário ou benefício e tentar de novo.
        </TextParagraph>
      </ColorWrapper>
    </Page>
  )
}

export default RefinanciamentoNaoLiberado
